import React from 'react'
import Layout from '../layout/layout'
import DbSections from '../components/db/db-sections'
import Summary from '../components/summary'

const Page = ({ pageContext }) => (
  <Layout contextEntity={pageContext.page} cssClass="page">
    <Summary data={pageContext.page} />
    <div id="main-content">
      <DbSections>{pageContext.page.sections}</DbSections>
    </div>
  </Layout>
)

export default Page
